import React from 'react';
import {Link, Redirect} from "react-router-dom";
import isEqual from 'lodash/isEqual';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown, faArrowUp, faPlus} from '@fortawesome/free-solid-svg-icons';

import {Loading, PaginationBar, ActionsButton} from 'Components/Partials';
import {Input} from 'Components/Form';
import {PrimaryButton, SecondaryButton} from 'Components/Button';
import FilterModal from 'Components/Utilities/FilterModal';
import ColumnsSelectModal from 'Components/Utilities/ColumnsSelectModal';

import {User} from 'Services';
import {convertValue, checkAccess} from 'Services/BaseHelpers';
import { ModalTrigger } from "Components/Modal";

/*
Example;

columns = [
    {
        label: 'First Name',
        value: 'first_name',
        type: 'string',
        orderable: 'first_name'
    },
    {
        label: 'Admin',
        value: 'is_admin',
        type: 'boolean',
        orderable: 'is_admin',
        filters: [
            {
                column: 'is_admin',
                label: 'Admin',
                component: BooleanFilter
            }
        ]
    },
    {
        label: 'Colour',
        value: (record) => (
            <div
                className="w-10 h-10 border"
                style={{backgroundColor: record.colour}}
            />
        ),
        type: 'callback',
        orderable: null,
        valueClassName: 'text-center'
    }
];

<IndexTable
    columns={this.columns}
    defaultFilters={{
        search: 'Tom Smith'
    }}
    defaultOrder={{
        order_by: 'first_name',
        order: 'asc'
    }}
    displaySearchBar={true}
    storeLink="/admin/users/store"
    storeModal={{
        component: CreatedModal,
        props:{
            id: selected?.id
        }
    }}
    loadDataCallback={(data) => AdminUsersApi.get(null, data)}
    rowActions={this.getRowActions}
    rowClickRedirect={(item) => "/service-users/show/"+item.id}
    eventApi={AdminUsersApi}
    report="Users"
/>

Column props;
 * @prop {string} label
 * @prop {mixed} value                  (See above for an example.)
 * @prop {string} type                  (All available column types are listed in BaseHelpers.js->convertValue().)
 * @prop {null|string} orderable
 * @prop {array} filters                (See above for an example.)
 * @prop {boolean} hideByDefault
 * @prop {string} valueClassName
 * @prop {boolean} valueClassNameOverwrite
 * Additionally all of the item props in BaseHelpers.js->checkAccess() are available to use on columns.

Props;
 * @prop {array} columns                (See above for an example.)
 * @prop {object|null} defaultFilters   (See above for an example.)
 * @prop {object|null} defaultOrder     (See above for an example.)
 * @prop {boolean} displaySearchBar
 * @prop {string|null} storeLink
 * @prop {string|null} storeModal       (See above for an example.)
 * @prop {function} loadDataCallback    (See above for an example.)
 * @prop {function|null} rowActions     (See Pages/Admin/Users/index.jsx for an example.)
 * @prop {string|null} rowActionsLayout (Defaults to "dropdown".)
 * @prop {function|null} rowClickRedirect (See above for an example.)
 * @prop {string|null} instructions     (Display this message above the IndexTable, e.g. "Click on a row to update".)
 * @prop {component|null} eventApi
 * @prop {string|null} report
 * @prop {string|null} itemName         {Defaults to "records".}
 * @prop {string|null} actionsJustify   {Defaults to "between".}
*/

export default class IndexTable extends React.Component {
    /**
     * @var classNames
     * @type {array}
     */
    classNames = {
        headerRow: 'bg-gray-300',
        row: (i) => (i % 2 ? 'bg-white' : 'bg-white'),
        headerCell: 'px-4 py-2 border border-app-leading text-center',
        cell: 'px-4 py-2 border border-app-leading text-center'
    }

    /**
     * @var state
     */
    state = {
        working: true,
        redirect: null,
        data: null,
        displayFilters: false,
        filters: this.props.defaultFilters ?? null,
        order: this.props.defaultOrder ?? {
            order_by: 'created_at',
            order: 'desc'
        },
        activeColumns: null,
        activeColumnHeader: null
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.loadData();

        this.loadDefaultState();

        if (this.props.eventApi) {
            this.props.eventApi.on('updated', this.loadData);
        }
    }

    /**
     * @method componentDidUpdate
     * @param prevProps
     */
    componentDidUpdate = async (prevProps) => {
        if (!isEqual(prevProps.columns, this.props.columns)) {
            this.loadDefaultState();
        }

        if (!isEqual(prevProps.defaultFilters, this.props.defaultFilters)) {
            this.handleFilters(this.props.defaultFilters);
        }
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        if (this.props.eventApi) {
            this.props.eventApi.removeListener('updated', this.loadData);
        }
    };

    /**
     * @method loadData
     * @param {integer} page
     */
    loadData = async (page = 1) => {
        this.setState({
            working: true,
        });

        const response = await this.props.loadDataCallback({
            page,
            ...this.state.filters,
            ...this.state.order
        });

        this.setState({
            working: false,
            data: response.data,
        });
    }

    /**
     * @method loadDefaultState
     * @param prevProps
     */
    loadDefaultState = () => {
        const {columns} = this.props;

        if (columns && columns[0]) {
            this.setState({
                activeColumns: columns
                    .filter(column => (!column.hideByDefault && this.shouldShowColumn(column)))
                    .map((column) => column.value),

                displayFilters: columns
                    .filter(column => column.filters && column.filters.length !== 0)
                    .length !== 0,
            });
        }
    }

    /**
     * @method loadDefaultState
     * @param {object} column
     * @param {boolean}
     */
    shouldShowColumn = (column) => {
        if (!checkAccess(column)) {
            return null;
        }

        return true;
    }

    /**
     * @method handleFiltersKey
     * @param {string} key
     * @param {string} value
     */
    handleFiltersKey = (key, value) => {
        window.clearTimeout(this.searchTimeout);

        const {filters} = this.state;

        this.setState({
            filters: {
                ...filters,
                [key]: value
            }
        }, () => {
            this.searchTimeout = setTimeout(this.loadData, 250);
        });
    };

    /**
     * @method handleFilters
     * @param {object} filters
     */
    handleFilters = (filters) => {
        window.clearTimeout(this.searchTimeout);

        this.setState({
            filters
        }, () => {
            this.loadData();
        });
    }

    /**
     * @method handleOrder
     * @param {string} order_by
     * @param {string} order
     */
    handleOrder = (order_by, order) => {
        this.setState({
            order: {
                order_by,
                order
            }
        }, this.loadData);
    };

    /**
     * @method handleRowRedirect
     * @param {object} datum
     */
    handleRowRedirect = (datum) => {
        const {rowClickRedirect} = this.props;

        if (rowClickRedirect) {
            this.setState({
                redirect: rowClickRedirect(datum)
            })
        }
    }

    /**
     * @method getTotal
     * @return {integer}
     */
    getTotal = () => {
        const {data} = this.state;

        return data?.meta?.total ?? data?.data?.length ?? 0;
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {itemName} = this.props;
        const {data, working, activeColumns, redirect} = this.state;

        if (redirect) {
            return <Redirect to={redirect} push />
        }

        return (
            <div className="mx-4 mb-4 h-full flex flex-col gap-8">
                {(!User.belongsToGroup('Service User Family') || User.isAdmin) &&
                    this.renderActions()
                }

                {working && (<Loading />)}

                {!working && this.getTotal() === 0 &&
                    <p className="font-bold text-center">
                        There are no {itemName ?? 'records'} to display.
                    </p>
                }

                {!working && (!activeColumns || activeColumns.length === 0) &&
                    <p className="font-bold text-center">
                        You must select at least one column to display.
                    </p>
                }

                {!working && activeColumns && activeColumns.length !== 0 &&
                    this.renderTable()
                }
            </div>
        )
    }

    /**
     * @method renderActions
     * @return {JSX.Element}
     */
    renderActions() {
        const {storeLink, storeModal, columns, displaySearchBar, report, actionsJustify = 'between'} = this.props;
        const {displayFilters, filters, activeColumns} = this.state;

        return (
            <div className={`flex flex-col sm:flex-row justify-${actionsJustify} items-center gap-4`}>
                {storeLink &&
                    <Link to={storeLink}>
                        <PrimaryButton
                            text="Add"
                        />
                    </Link>
                }

                {storeModal &&
                    <ModalTrigger
                        component={storeModal.component}
                        props={storeModal.props}
                    >
                        <PrimaryButton
                            text="Add"
                        />
                    </ModalTrigger>
                }

                {displaySearchBar &&
                    <Input
                        containerClassName="w-full"
                        className="mt-0"
                        type="text"
                        value={filters?.search ?? ''}
                        onChange={(v) => this.handleFiltersKey('search', v)}
                        placeholder="Search..."
                    />
                }

                {filters && Object.values(filters).filter(f => f).length !== 0 &&
                    <SecondaryButton
                        text="Clear All Filters"
                        onClick={() => this.handleFilters(null)}
                        className="min-w-max"
                    />
                }

                {displayFilters &&
                    <ModalTrigger
                        component={FilterModal}
                        props={{
                            columns,
                            filters,
                            handleFiltersCallback: this.handleFilters
                        }}
                    >
                        <PrimaryButton
                            text="Filter"
                        />
                    </ModalTrigger>
                }

                {report && User.data.is_admin &&
                    <Link to={`/admin/reports?report=${report}`}>
                        <PrimaryButton
                            text="Download"
                        />
                    </Link>
                }
            </div>
        );
    }

    /**
     * @method renderTable
     * @return {JSX.Element}
     */
    renderTable() {
        const {columns, rowActions, rowClickRedirect, instructions} = this.props;
        const {data} = this.state;

        let total = this.getTotal();

        return (
            <div className={`h-full flex flex-col ${total !== 0 ? 'justify-between' : 'justify-end'}`}>
                {total !== 0 &&
                    <div className="overflow-x-auto">
                        {instructions &&
                            <p className="italic text-center mb-6">
                                {instructions}
                            </p>
                        }

                        <table className="table-auto mx-auto">
                            <thead>
                                <tr className={this.classNames.headerRow}>
                                    {columns.map((column, i) => this.renderColumnHeader(column, i))}

                                    {this.renderColumnSelect()}
                                </tr>
                            </thead>
                            <tbody>
                                {data.data.map((datum, i) => {
                                    return (
                                        <tr
                                            className={`
                                                ${this.classNames.row(i)}
                                                ${rowClickRedirect ? 'hover:bg-blue-100 cursor-pointer' : ''}
                                            `}
                                            key={i}
                                        >
                                            {columns.map((column, j) => this.renderColumnValue(datum, column, i, j))}

                                            {rowActions && this.renderRowActions(datum)}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                }

                {data?.meta &&
                    <div className="mt-12">
                        <PaginationBar
                            total={data.meta.total}
                            pageCount={data.meta.last_page}
                            page={data.meta.current_page}
                            goToPage={this.loadData}
                        />
                    </div>
                }
            </div>
        );
    }

    /**
     * @method renderColumnHeader
     * @param {object} column
     * @param {integer} key
     * @return {JSX.Element}
     */
    renderColumnHeader(column, key) {
        const {activeColumns} = this.state;

        // Check if active column.
        if (!activeColumns.includes(column.value)) {
            return null;
        }

        return this.renderColumnHeaderActions(column, key);

        return (
            <th className={this.classNames.headerCell} key={key}>
                {column.label}
            </th>
        );
    }

    /**
     * @method renderColumnHeader
     * @param {object} column
     * @param {integer} key
     * @return {JSX.Element}
     */
    renderColumnHeaderActions(column, key) {
        const {filters, order, activeColumns, activeColumnHeader} = this.state;

        const styles = 'block py-2 px-4 font-medium text-white cursor-pointer';

        return (
            <th className={this.classNames.headerCell} key={key}>
                <div
                    className="flex justify-center items-center gap-1 cursor-pointer select-none"
                    onClick={() => this.setState({
                        activeColumnHeader: activeColumnHeader !== key ? key : null
                    })}
                >
                    {order.order_by === column.orderable &&
                        <FontAwesomeIcon icon={order.order === 'asc' ? faArrowDown : faArrowUp} />
                    }

                    {column.label}
                </div>

                {activeColumnHeader === key &&
                    <div className={`
                        absolute mt-2 rounded-md shadow-lg z-20 bg-light-blue-700 text-left
                        py-1 ring-1 ring-black ring-opacity-5
                    `}>
                        {column.orderable &&
                            <>
                                <div
                                    onClick={() => {
                                        this.handleOrder(column.orderable, 'asc');
                                        this.setState({activeColumnHeader: null});
                                    }}
                                    className={`${styles} hover:bg-gray-100 hover:text-black`}
                                >
                                    Sort Ascending
                                </div>

                                <div
                                    onClick={() => {
                                        this.handleOrder(column.orderable, 'desc');
                                        this.setState({activeColumnHeader: null});
                                    }}
                                    className={`${styles} hover:bg-gray-100 hover:text-black`}
                                >
                                    Sort Descending
                                </div>
                            </>
                        }

                        <div
                            onClick={() => this.setState({
                                activeColumns: activeColumns.filter(c => c !== column.value),
                                activeColumnHeader: null
                            })}
                            className={`${styles} hover:bg-gray-100 hover:text-black`}
                        >
                            Hide Column
                        </div>

                        {column.filters && column.filters.map((filter, i) => {
                            let Filter = filter.component;

                            return (
                                <div className={`${styles} text-black`} key={i}>
                                    <Filter
                                        onChange={(column, value) => this.handleFiltersKey(column, value)}
                                        filters={filters}
                                        column={filter.column}
                                        props={filter.props}
                                        defaultMethod={filter.defaultMethod}
                                    />
                                </div>
                            );
                        })}
                    </div>
                }
            </th>
        );
    }

    /**
     * @method renderColumnSelect
     * @return {JSX.Element}
     */
    renderColumnSelect() {
        const {columns} = this.props;
        const {activeColumns} = this.state;

        if (columns.filter(this.shouldShowColumn).length === activeColumns.length) {
            return null;
        }

        return (
            <th className={`${this.classNames.headerCell} text-center`}>
                <div className="flex justify-center items-center h-full">
                    <ModalTrigger
                        component={ColumnsSelectModal}
                        props={{
                            callback: (v) => this.setState({activeColumns: v}),
                            columns: columns.filter(this.shouldShowColumn),
                            activeColumns
                        }}
                    >
                        <div
                            className="bg-light-blue-700 text-white px-3 py-2 rounded-md shadow-lg cursor-pointer text-sm"
                            title="Click to select columns..."
                        >
                            <FontAwesomeIcon icon={faPlus} size="1x" />
                        </div>
                    </ModalTrigger>
                </div>
            </th>
        );
    }

    /**
     * @method renderColumnHeader
     * @param {object} record
     * @param {object} column
     * @param {integer} key1
     * @param {integer} key2
     * @return {JSX.Element}
     */
    renderColumnValue(record, column, key1, key2) {
        const {activeColumns} = this.state;

        // Check if active column.
        if (!activeColumns.includes(column.value)) {
            return null;
        }

        return (
            <td
                onClick={() => this.handleRowRedirect(record)}
                className={column.valueClassNameOverwrite ? column.valueClassName : `${this.classNames.cell} ${column.valueClassName}`}
                key={key2}
            >
                {convertValue(column, record)}
            </td>
        );
    }

    /**
     * @method renderRowActions
     * @param {object} item
     * @return {JSX.Element}
     */
    renderRowActions(item) {
        const {rowActions, rowActionsLayout} = this.props;

        return (
            <td className={`${this.classNames.cell} text-center`}>
                <ActionsButton buttons={rowActions(item)} layout={rowActionsLayout} />
            </td>
        );
    }
}
